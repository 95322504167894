<template>
  <div>
    <!-- 零售明细查询 -->
    <filter-view :loading="loading" @submit="loadEchart">
      <retail-Filter :show-opt="showOpt" :query="query" :status="status" :payStatus="payStatus" @init="loadEchart" />
    </filter-view>

    <div class="result">
      <el-card shadow="never" v-if="count">
        <div class="h">
          <div class="money-count-item">
            <div class="fc-g">原单金额</div>
            <counter class="num" :end-val="count.amount / 100" :decimals="2" :duration="1000" />
          </div>
          <div class="money-count-item">
            <div class="fc-g">销售金额</div>
            <counter class="num" :end-val="count.realAmount / 100" :decimals="2" :duration="1000" />
          </div>
        </div>
      </el-card>
      <div class="padding-10" v-if="storeData && storeData.length > 0">
        <div>数据列表</div>
        <div v-for="(o, i) in storeData" :key="i" class="text item padding-10-0">
          <div class="c h">
            <el-card class="box-card fs-small lh-150" shadow="never" style="width: 100%">
              <div>
                销售单号
                <span class="padding-0-05" />
                {{ o.formCode || "--" }}
              </div>
              <div>
                经销商
                <span class="padding-0-05" />
                {{ o.distributorName || "--" }}
              </div>
              <div>
                门店
                <span class="padding-0-05" />
                {{ o.shopName || "--" }}
              </div>
              <div>
                商品
                <span class="padding-0-05" />
                {{ o.goodsName || "--" }}
              </div>
              <div>
                规格
                <span class="padding-0-05" />
                {{
                $goodsSpecConvert(o.specs) || "--"
                }}
              </div>
              <div>
                品牌
                <span class="padding-0-05" />
                {{ o.brandName || "--" }}
              </div>
              <div>
                数量
                <span class="padding-0-05" />
                {{ o.quantity || "--" }}
              </div>
              <div>
                统一零售价
                <span class="padding-0-05" />
                {{
                $price(o.price) || "--"
                }}
              </div>
              <div>
                成交价
                <span class="padding-0-05" />
                {{
                $price(o.realPrice) || "--"
                }}
              </div>
              <div>
                金额
                <span class="padding-0-05" />
                {{ $price(o.amount) || "--" }}
              </div>
              <div>
                已发货数量
                <span class="padding-0-05" />
                {{
                $price(o.sendCount) || "--"
                }}
              </div>
              <div>
                已取消数量
                <span class="padding-0-05" />
                {{
                $price(o.cancelCount) || "--"
                }}
              </div>
            </el-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import filterView from "../assembly/filter";
import retailFilter from "../assembly/filter/retail";
import request from "@/utils/request";
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { sumDistributorDetails } from "@/api/shopOrder";
import Counter from "vue-count-to";
export default {
  name: "saleInfoAnaly",
  components: {
    filterView,
    retailFilter,
    Counter,
  },
  mixins: [initData],
  data() {
    let now = new Date();
    return {
      loading: false,
      downloadLoading: false,
      count: null,
      params: null,
      storeData: [],
      showOpt: {
        showGoodsType: true, //商品分类
        showDimension: false, //分析维度隐藏
        showRangGoods: false, //商品范围
        showCommodity: false, //指定商品
        showRank: false, //显示排名对象
        showReported: false, //仅显示已上报
        showYearonyear: false, //同比显示
        showMonth: false, //环比显示
        showRankGist: false, //显示排名依据
        showLevel: false, //汇总级别
        showCompareyear: false, //同比年度
        showTimeRange: false, //时间范围
        showDetail: true, //显示零售明细查询
      },
      query: {
        addressType: "area",
        area: {
          treeNodeId: "1",
          treeNodeType: "group",
        },
        standard: {
          treeNodeId: "",
          StandardAreaGroup: "country", //标准行政区域
        },
        beginTime: new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          now.getDate()
        ).getTime(),
        endTime: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          23,
          59,
          59
        ).getTime(),

        status: null,
        isPrepare: false,
        payStatus: null,
        keywords: null,
        goodsName: null,
        salesmanId: null,
      },
      payStatus: {
        all: "全部已收",
        part: "部分已收",
        zero: "待收款",
      },
      status: {
        input: { key: "input", label: "录入状态", type: "info" },
        submit: { key: "submit", label: "提交状态", type: "" },
        auditing: { key: "auditing", label: "审核中", type: "" },
        pass: { key: "pass", label: "审核通过", type: "success" },
        fail: { key: "fail", label: "审批未通过", type: "danger" },
        callback: { key: "callback", label: "退回修改", type: "info" },
        sendPart: { key: "sendPart", label: "部分已发货", type: "success" },
        sended: { key: "sended", label: "已发货", type: "success" },
        closedPart: { key: "closedPart", label: "部分关闭", type: "info" },
        closed: { key: "closed", label: "已关闭", type: "info" },
        cancel: { key: "cancel", label: "已取消", type: "info" },
      },
    };
  },
  methods: {
    makeParams() {
      let query = JSON.parse(JSON.stringify(this.query));
      this.params = Object.assign(
        { page: 0, size: 999999, sort: "purchaseTime,desc" },
        query
      );
      if (this.params.isPrepare) {
        this.params.statusList = [];
      }
      let params = this.params;
      
      if (params.addressType == "standard") {
        Object.assign(params, params.standard);
      } else {
        Object.assign(params, params.area);
      }
      delete params.standard;
      delete params.area;
    },

    // beforeInit() {
    //   this.url = "api/shop/orderFormFull/forDistributor";
    //   let query = JSON.parse(JSON.stringify(this.query));
    //   this.params = Object.assign(
    //     { page: 0, size: 999999, sort: "purchaseTime,desc" },
    //     query
    //   );
    //   if (this.params.isPrepare) {
    //     this.params.statusList = [];
    //   }
    //   let params = this.params;
    //   if (params.addressType == "standard") {
    //     if (query.StandardAreaGroup == 0) {
    //       params.StandardAreaGroup = "country";
    //     }
    //     if (query.StandardAreaGroup == 1) {
    //       params.StandardAreaGroup = "province";
    //     }
    //     if (query.StandardAreaGroup == 2) {
    //       params.StandardAreaGroup = "city";
    //     }
    //     if (query.StandardAreaGroup == 3) {
    //       params.StandardAreaGroup = "district";
    //     }
    //     delete params.treeNodeType;
    //   } else {
    //     params.treeNodeType = this.query.treeNodeType;
    //     delete params.StandardAreaGroup;
    //   }
    //   return true;
    // },
    loadSums() {
      let query = JSON.parse(JSON.stringify(this.query));
      let params = Object.assign(
        { page: 0, size: 999999, sort: "purchaseTime,desc" },
        query
      );
      sumDistributorDetails(params).then((res) => {
        this.count = res;
      });
    },
    toQuery() {
      let data = this.makeParams();
      this.loading = true;
      request({
        url: "api/shop/orderFormFull/forDistributor",
        method: "get",
        params: data,
      })
        .then((res) => {
          this.storeData = res.content;
        })
        .catch((err) => {
          this.storeData = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadEchart() {
      this.loadSums();
      this.toQuery();
    },
  },
};
</script>

<style>
</style>